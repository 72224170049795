// FADE TO TOP
var $animation_elements = $(".animated");
var $window = $(window);

function check_if_in_view() {
    var window_height = $window.height();
    var window_top_position = $window.scrollTop();
    var window_bottom_position = window_top_position + window_height;

    $.each($animation_elements, function () {
        var $element = $(this);
        var element_height = $element.outerHeight();
        var element_top_position = $element.offset().top;
        var element_bottom_position = element_top_position + element_height;

        //check to see if this current container is within viewport
        if (
            element_bottom_position >= window_top_position &&
            element_top_position <= window_bottom_position
        ) {
            $element.addClass("active");
        }
        //else {
        //$element.removeClass('active');
        //}
    });
}

$window.on("scroll resize", check_if_in_view);
$window.trigger("scroll");

// IMAGE REPLACE - img src to bg css
function replaceSrc() {
    $(".js-replace-img").each(function () {
        var src = $(this).find("img").attr("src");
        $(this).css("background-image", "url(" + src + ")");
    });
}

jQuery(function () {
    if ($(".js-replace-img")) {
        replaceSrc();
    }
});

// SCROLL FADE HEADER - Slideshow

var viewHeight = window.innerHeight;
var header = $(".slideshow");
$(window).scroll(function (event) {
    var scroll = $(window).scrollTop();
    var calcul = scroll / 500;
    $(".slideshow").css("opacity", 1 - calcul);
});

(function () {
    var s = {
        menuState: function () {
            $(this).toggleClass("cross");
            $(".main-nav-wrapper").toggleClass("open");
        },

        headerHeight: function () {
            if ($(window).scrollTop() > 0) {
                return $(".main-header").height();
            } else {
                return $(".main-header").height() - 24;
            }
        },

        headerPosition: function () {
            var top = $(window).scrollTop();

            if (top > 72) {
                $(".main-header").addClass("min");
                $(".sub-nav").addClass("min");
            } else {
                $(".main-header").removeClass("min");
                $(".sub-nav").removeClass("min");
            }
        },

        events: function () {
            $(window).scroll(this.headerPosition);
            $("#btnMobile").on("click", this.menuState);
        },

        init: function () {
            this.headerPosition();
            this.events();
        },
    };

    s.init();
})();

// NAVIGATION
// -- MAIN

$("ul > li.menu-item-has-children > a").click(function (e) {
    e.preventDefault();
    $("ul li ul").removeClass("open");
    $(this).next(".sub-menu").toggleClass("open");
});

// -- ASIDE MENU

$(".btn-aside-menu").click(function () {
    $(".children-links").toggleClass("aside-menu-open");
    $(".btn-aside-menu").toggleClass("aside-open");
});

// -- CLOSE NAV WHEN CLICK ANYWHERE
$(document).on("click", function (event) {
    var $trigger = $(".menu-item-has-children");
    if ($trigger !== event.target && !$trigger.has(event.target).length) {
        $("ul.sub-menu").removeClass("open");
    }
});
